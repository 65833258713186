<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary border-0 mb-0" v-loading="isLoading">
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-muted mb-4">
              <small>{{ $t('Login') }}</small>
            </div>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
              <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <base-input
                  alternative
                  type="email"
                  class="mb-3"
                  name="email"
                  :rules="{ required: true, email: true }"
                  prepend-icon="ni ni-email-83"
                  :placeholder="$t('pages.login.fields.email.placeholder')"
                  v-model="email"
                />
                <base-input
                  alternative
                  class="mb-3"
                  name="password"
                  :rules="{ required: true, min: 6 }"
                  prepend-icon="ni ni-lock-circle-open"
                  type="password"
                  :placeholder="$t('pages.login.fields.password.placeholder')"
                  v-model="password"
                />
                <div class="text-center">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4"
                    :disabled="!requiredFields"
                    >{{ $t('Login') }}</base-button
                  >
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
    }
  },

  computed: {
    requiredFields() {
      return this.email !== '' && this.password !== ''
    },
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
    }),

    async onSubmit() {
      if (this.requiredFields) {
        this.isLoading = true

        const ok = await this.login({
          email: this.email,
          password: this.password,
        })

        if (!ok)
          this.$notify({ type: 'danger', message: 'Login ou senha inválidos' })

        this.password = ''
        this.isLoading = false
      }
    },
  },
}
</script>
